import Vue from 'vue'
import Vuex from 'vuex'
import {
	setProp,
	deleteProp
} from 'bh-mod'
import {
	floor
} from 'lodash'

Vue.use(Vuex)

export default {
	state: {
		updatedKey: Date.now(),
		currentUnitCategory: 'all',
		units: [],
		condo: {},
		floors: [],
		selectedTags: [],
		tags: [],
		editUnit: {},
		getStarted: false,
		unitDialog: {
			show: false,
			type: 'add'
		},
		currentFloor: 1,
		allSettings: {},
		display: 'block',
		modal: {
			visible: false,
			type: '',
			edit: false,
			obj: {}
		},
		openNav: false,
		deleteFloor: false,
	},
	mutations: {
		DEL_FLOOR(state) {
			state.deleteFloor = true
		},
		UPDATE_NAV(state, data) {
			state.openNav = data
		},
		CLOSE_MODAL(state) {
			state.modal.visible = false
			state.modal.type = ''
			state.modal.edit = false
			state.modal.obj = {}
		},
		OPEN_MODAL(state, data) {
			state.modal.visible = true
			state.modal.type = data
		},
		EDIT_MODAL(state, data) {
			state.modal.visible = true
			state.modal.type = data.type
			state.modal.edit = true
			state.modal.obj = data.obj
		},
		CHANGE_DISPLAY: (state, data) => {
			state.display = data
		},
		SET_SETTINGS: (state, data) => {
			let temp = data
			if (data.userApp == null) {
				temp.userApp = {
					options: {
						seenIntro: false,
					}
				}
				state.allSettings = temp
			}
			if (data.app == null){
				temp.app = {
					options: {
						customFields:[],
						premiums:[],
						addons:{
							parking:[],
							lockers:[],
							bikeRacks:[]
						},
					}
				}

			}
			if (data.app && data.app.options && !data.app.options.addons){
				temp.app.options.addons = {
					parking:[],
					lockers:[],
					bikeRacks:[]
				}
			}
			state.allSettings = temp
		},
		UPDATE_SETTINGS(state, data) {
			state.allSettings.app = data
		},
		UPDATE_ADDON_SETTINGS(state) {
			if (state.allSettings && state.allSettings.app && state.allSettings.app.options && !state.allSettings.app.options.addons) {
				state.allSettings.app.options.addons = {
					parking: [],
					lockers: [],
					bikeRacks: []
				}
			}
		},
		DELETE_FLOOR: (state, data) => {
			let deleteIndex = state.floors.findIndex(floor => floor.id == data.id)
			state.floors.splice(deleteIndex, 1)
			let orders = state.floors.map(x => x.order)
			let min = Math.min(...orders)
			state.currentFloor = min
		},
		RENAME_FLOOR: (state, data) => {
			// for (var i in state.floors) {
			//   if (state.floors[i].id == data.id) {
			//     setProp(state,['floors',i],data)
			//   }
			// }
			let index = state.floors.findIndex(floor => floor.id === data.id)
			if (index >= 0) setProp(state, ['floors', index, 'name'], data.name)

		},
		CHANGE_FLOOR: (state, data) => {
			state.currentFloor = data
		},
		ADD_FLOOR: (state, data) => {
			state.floors = data
		},
		SET_PROP: (state, { where, what, del = false,update = false}) => {
			if (del) return deleteProp(state, where)
			state.newStoreTime = Date.now()
			setProp(state, where, what)
		},
		ADD_UNIT: (state, data) => {
			setProp(state, ['units', data.id], data)
			let tags = data.tags
			let newTags = []
			tags.forEach(tag => {
				let found = state.tags.find(x => x.id === tag.id)
				if (!found) {
					newTags.push(tag)
				}
			})
			// state.units[data.id] = data
		},
		SHOW_FLOOR: (state, data) => {
			if (data != 'settings') {
				let floor = state.floors.find(floor => floor.id == data)
				if (floor) state.currentFloor = floor.order
				else state.currentFloor = 0
			} else {
				state.currentFloor = data
			}
		},
		SHOW_UNIT_PAGE: (state, data) => {
			state.currentUnitCategory = data

		},
		UPDATE_UNIT: (state, data) => {
			// state.units[data.id] = data
			setProp(state, ['units', data.id], data)
			let tags = data.tags
			tags.forEach(tag => {
				let found = state.tags.find(x => x.id === tag.id)
				if (!found) {
					state.tags.push(tag)
				}
			})
		},
		DELETE_UNIT: (state, id) => {
			deleteProp(state, ['units', id])
			// delete state.units[id]
		},
		UPDATE_CONDO(state, data){
			state.condo = data;
		},
		SET_APPDATA(state, data) {
			if (typeof data === 'object') {
				Object.entries(data).forEach(([key, value]) => {
					if (key === 'units') {
						let obj = {}
						value.forEach(x => {
							obj[x.id] = x
						})
						state[key] = obj

					} else if (key == 'condo') {
						let {
							floors,
							...condo
						} = value
						state.condo = condo
						state.floors = floors
					} else {
						state[key] = value
					}
				})
			}
			// state.units.forEach(unit => {
			//   if (unit.tags){
			//     elevation.tagIDs = elevation.tags.map(x => x.id)
			//   }
			// })
		},
		GET_STARTED: state => {
			state.getStarted = true
			state.unitTypeDialog = {
				show: true,
				type: 'add'
			}
		},
		TOGGLE_TAG: (state, id) => {
			let findIndex = state.selectedTags.findIndex(x => x === id)
			if (findIndex > -1) state.selectedTags.splice(findIndex, 1)
			else state.selectedTags.push(id)
		},
		CLOSE_DIALOG: state => {
			state.unitDialog.show = false
			state.updatedKey = Date.now()
		},
		CHECK_ADD_TAGS: (state, data) => {
			let tags = state.tags.map(x => x.id)
			data.forEach(newTag => {
				if (!tags.includes(newTag.id))
					state.tags.push(newTag)
			})

		},
		SHOW_ADD_UNIT: state => {
			state.unitDialog = {
				show: true,
				type: 'add'
			}
		},
		SHOW_EDIT_UNIT: (state, unit) => {
			state.unitDialog = {
				show: true,
				type: 'edit'
			}
			state.editUnit = unit
		},
	},
	actions: {
		SET_APPDATA: async ({
			state,
			commit,
			dispatch
		}, data) => {
			if (data) {
				commit('SET_APPDATA', data)
			}
		},
		closeDialog({
			commit
		}, {
			type,
			data
		}) {
			if (type.includes('add')) {
				commit('ADD_UNIT', data)
			} else if (type.includes('edit')) {
				commit('UPDATE_UNIT', data)
			} else if (type.includes('delete')) {
				commit('DELETE_UNIT', data)
			}

			commit('CLOSE_DIALOG')
		}
	},
	getters: {
		currentUnits: (state, getters) => {
			let cat = state.currentUnitCategory
			if (cat === 'all') {
				let selectedFloor = []
				let units = Object.values(state.units)
				selectedFloor = units.filter(unit => unit.unitGroup ? unit.unitGroup.order == state.currentFloor : false)
				if (!selectedFloor) return []
				else return selectedFloor
			} else {
				let allUnits = getters.unitsFiltered[cat]
				let floorUnits = []
				floorUnits = allUnits.filter(unit => unit.unitGroup ? unit.unitGroup.order == state.currentFloor : false)
				if (!floorUnits) return []
				else return floorUnits
			}
		},
		unitsFiltered: state => {
			let units = state.units
			let unitCategories = {}
			Object.values(units).forEach(unit => {

				let pkg = {}
				if (unit.packages && unit.packages[0]){
					pkg = unit.packages[0]
				}
				let {
					beds = false, hasDen, hasCondo, hasRoof, hasTerrace, hasPenthouse
				} = pkg

				if (hasCondo) {
					if (!unitCategories['Condotowns']) unitCategories['Condotowns'] = []
					unitCategories['Condotowns'].push(unit)
				}
				if (hasRoof) {
					if (!unitCategories['Roof Top']) unitCategories['Roof Top'] = []
					unitCategories['Roof Top'].push(unit)
				}
				if (hasTerrace) {
					if (!unitCategories['Terrace Suites']) unitCategories['Terrace Suites'] = []
					unitCategories['Terrace Suites'].push(unit)
				}
				if (hasPenthouse) {
					if (!unitCategories['Loft Suites']) unitCategories['Loft Suites'] = []
					unitCategories['Loft Suites'].push(unit)
				}
				if (beds) {

					let bedCat = `${beds} Bedrooms${hasDen? ' + Den' : ''}`

					if (!unitCategories[bedCat]) unitCategories[bedCat] = []
					unitCategories[bedCat].push(unit)
				}

			})

			return unitCategories




		},
		floorFiltered: state => {
			let floorFiltered = {}
			Object.values(state.units).forEach(unit => {
				let floorObj = {}
				let pkg = unit.packages && unit.packages[0] || {}
				let {
					beds = false, hasDen, hasCondo, hasRoof, hasTerrace, hasPenthouse
				} = pkg
				if (unit.unitGroup && unit.unitGroup.id){
					if (hasCondo) {
						if (!floorFiltered[unit.unitGroup.id]) {
							if (!floorObj['Condotowns']) floorObj['Condotowns'] = []
							floorObj['Condotowns'].push(unit)
						} else {
							if (!floorFiltered[unit.unitGroup.id]['Condotowns']) floorFiltered[unit.unitGroup.id]['Condotowns'] = []
							floorFiltered[unit.unitGroup.id]['Condotowns'].push(unit)
						}
					}
					if (hasRoof) {
						if (!floorFiltered[unit.unitGroup.id]) {
							if (!floorObj['Roof Top']) floorObj['Roof Top'] = []
							floorObj['Roof Top'].push(unit)
						} else {
							if (!floorFiltered[unit.unitGroup.id]['Roof Top']) floorFiltered[unit.unitGroup.id]['Roof Top'] = []
							floorFiltered[unit.unitGroup.id]['Roof Top'].push(unit)
						}
					}
					if (hasTerrace) {
						if (!floorFiltered[unit.unitGroup.id]) {
							if (!floorObj['Terrace Suites']) floorObj['Terrace Suites'] = []
							floorObj['Terrace Suites'].push(unit)
						} else {
							if (!floorFiltered[unit.unitGroup.id]['Terrace Suites']) floorFiltered[unit.unitGroup.id]['Terrace Suites'] = []
							floorFiltered[unit.unitGroup.id]['Terrace Suites'].push(unit)
						}
					}
					if (hasPenthouse) {
						if (!floorFiltered[unit.unitGroup.id]) {
							if (!floorObj['Loft Suites']) floorObj['Loft Suites'] = []
							floorObj['Loft Suites'].push(unit)
						} else {
							if (!floorFiltered[unit.unitGroup.id]['Loft Suites']) floorFiltered[unit.unitGroup.id]['Loft Suites'] = []
							floorFiltered[unit.unitGroup.id]['Loft Suites'].push(unit)
						}
					}
					if (beds) {

						let bedCat = `${beds} Bedrooms${hasDen? ' + Den' : ''}`
						if (!floorFiltered[unit.unitGroup.id]) {
							if (!floorObj[bedCat]) floorObj[bedCat] = []
							floorObj[bedCat].push(unit)
						} else {
							if (!floorFiltered[unit.unitGroup.id][bedCat]) floorFiltered[unit.unitGroup.id][bedCat] = []
							floorFiltered[unit.unitGroup.id][bedCat].push(unit)
						}
					}
					if (!floorFiltered[unit.unitGroup.id]) floorFiltered[unit.unitGroup.id] = floorObj
				}

			})
			return floorFiltered
		},
		units: state => {
			if (!state.selectedTags.length) return Object.values(state.units)
			return Object.values(state.units).filter(unit => {
				let unitTags = unit.tags.map(x => x.id)
				let found = true
				state.selectedTags.forEach(tagID => {
					if (!unitTags.includes(tagID)) found = false
				})

				return found
			})
		},
		floorsNav: state => {
			let floors = state.floors.sort((a, b) => a.order - b.order)
			return floors.map((floor, floorI) => {
				return {
					label: floor.name,
					value: floor.id,
					parent: 'all',
					options: [{
							label: 'Rename',
							value: 'rename'
						},
						{
							label: 'Unpublish',
							value: 'unpublish'
						},
						{
							label: 'Delete',
							value: 'delete'
						}
					]
				}
			})
		},
		sidebarItems: (state, getters) => {
			let floorList = []
			state.floors.forEach(floor => {
				let floorObj = {}
				floorObj.label = floor.name
				floorObj.order = floor.order
				floorObj.value = floor.id
				floorObj.iconUrl = require("@/assets/sideIcons/floor.svg"),
				floorObj.options = [{
						label: 'Rename',
						value: 'rename'
					},
					{
						label: 'Unpublish',
						value: 'unpublish'
					},
					{
						label: 'Delete',
						value: 'delete'
					}
				]
				floorObj.children = []
				if (getters.floorFiltered[floor.id]) {
					Object.keys(getters.floorFiltered[floor.id]).forEach(filFloor => {
						let child = {}
						child.label = filFloor
						child.value = filFloor
						child.options = [{
								label: 'Unpublish',
								value: 'unpublish'
							},
							{
								label: 'Delete',
								value: 'delete'
							}
						]
						child.parent = floor.id
						floorObj.children.push(child)
					})
					floorObj.children.sort((a, b) => {
						if (a.label > b.label) return 1
						else if (a.label < b.label) return -1
						else return 0
					})
				}
				floorList.push(floorObj)
			})
			floorList = floorList.sort((a, b) => {
				if (a.order > b.order) return 1
				else if (a.order < b.order) return -1
				else return 0
			})
			floorList.push({
				divider: true
			})
			let settings = {
				label: 'Settings',
				value: 'settings',
				options: [],
				children: [],
				iconUrl: require("@/assets/sideIcons/settings.svg"),
				disabledSecondNav: true
			}
			floorList.push(settings)

			return floorList
		},
		measurement: (state, getters) => {
			let measurement;
			if (!state.allSettings.instance || !state.allSettings.options || !state.allSettings.options.measurement) {
				measurement = 'ft'
			} else if (state.allSettings.instance.options.measurement) {
				measurement = state.allSettings.instance.options.measurement
			}
			return measurement
		},
		cardName: (state, getters) => {
			let cardName
			let unitCategory = state.currentUnitCategory
			let currentFloor = state.currentFloor
			let floors = state.floors
			let floor = state.floors.find(floor => floor.order === currentFloor)

			// if (unitCategory == 'all') {
			//     for (var i in floors) {
			//         if (currentFloor == floors[i].order) {
			//             if (floors[i].hasOwnProperty('name')) {
			//                 cardName = floors[i].name
			//             } else {
			//                 cardName = `Floor ${currentFloor+1}`
			//             }
			//         }
			//     }

			// }
			// if (unitCategory == 'all') {
			if (!floor) return 'Undefined'
			else return floor.name || `Floor ${state.currentFloor}`
			// }
			// else return unitCategory
			// return cardName
		}
	},
}
