import Vue from 'vue'
import Vuex from 'vuex'
import user from './user'
import condoUnits from './appData'
import settings from './settings'
import library from './library'

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		user,
		settings,
		condoUnits,
		library
	},
	state: {
		loading: false,
		instances: [],
		instance: null,
		hives: [],
		cats: [],
		uploadDialog: false,
		folders: [],
		files: {},
		currentFolder: '/',
		theApp: {},
		pageButtons: [],
		crumbs: [],
		fileStructure: [],
		importType: 'unit'
	},
	mutations: {
		SET_FILE_STRUCTURE(state, data) {
			state.fileStructure = data;
		},
		LOAD: (state, bool = !state.loading) => state.loading = Boolean(bool),
		SET_CRUMBS: (state, data) => state.crumbs = data,
		CLEAR_CRUMBS: (state) => state.crumbs = [],
		PAGE_BUTTONS(state, buttons) {
			state.pageButtons = buttons
		},
		SET_APP(state, data) {
			state.theApp = data
		},
		cancelUpload: (state) => {
			state.uploadDialog = false
		},
		uploadDialog: (state, folders = []) => {
			state.uploadDialog = true
			state.folders = folders
		},
		SET_INSTANCE: (state, data) => {
			state.instance = data
			state.cats = data.activeApps
		},
		SET_IMPORT_TYPE: (state, data) => {
			state.importType = data
		},
	},
	getters: {
		isPremiumPackage: state => {
			return state && state.instance && state.instance.package && ["PA-CON-HRP"].includes(state.instance.package.itemCode)
		}
	}
})
